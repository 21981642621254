import FreeIcon from '@material-ui/icons/MoneyOff';
import SecureIcon from '@material-ui/icons/Security';
import PrivateIcon from '@material-ui/icons/Https';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import FastIcon from '@material-ui/icons/ShutterSpeed';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import Hint from 'components/articles/Hint';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 2),
    backgroundColor: grey[50],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  factsTitle: {
    marginBottom: theme.spacing(4),
    position: 'relative',
    '&:after': {
      content: '""',
      width: 120,
      height: 6,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: 0,
      bottom: -16,
    },
  },
  factsList: {
    paddingInlineStart: 0,
    listStyleType: 'none',
  },
  factsItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  factsItemIcon: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main,
  },
}));

function Facts() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <Typography component="header" variant="h3" align="left" color="textPrimary" className={classes.factsTitle}>
          Why PhotoUtils?
        </Typography>
        <ul className={classes.factsList}>
          <li className={classes.factsItem}>
            <FreeIcon className={classes.factsItemIcon} fontSize="large" />
            <Typography variant="h5" component="p">
              <strong>Free.</strong> Edit images online for absolutely free! No tricks or hidden conditions.
            </Typography>
          </li>
          <li className={classes.factsItem}>
            <SecureIcon className={classes.factsItemIcon} fontSize="large" />
            <Typography variant="h5" component="p">
              <strong>Secure.</strong> Your images are uploaded to an encrypted storage,{' '}
              <MuiLink target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/HTTPS">
                HTTPS
              </MuiLink>{' '}
              is used to guarantee a secure communication between you and PhotoUtils.
            </Typography>
          </li>
          <li className={classes.factsItem}>
            <PrivateIcon className={classes.factsItemIcon} fontSize="large" />
            <Typography variant="h5" component="p">
              <strong>Private.</strong> We never ask you to register, we never share images you upload with anyone and
              we automatically remove them forever within 30 minutes after processing. Check the{' '}
              <MuiLink to="/privacy" component={Link}>
                Privacy policy
              </MuiLink>{' '}
              for details.
            </Typography>
          </li>
          <li className={classes.factsItem}>
            <HighQualityIcon className={classes.factsItemIcon} fontSize="large" />
            <Typography variant="h5" component="p">
              <strong>High quality.</strong> We do our best to preserve the original quality of the uploaded image while
              editing.
            </Typography>
          </li>
          <li className={classes.factsItem}>
            <FastIcon className={classes.factsItemIcon} fontSize="large" />
            <Typography variant="h5" component="p">
              <strong>Fast.</strong> Resize, convert, crop, compress, rotate, flip even large images just in a few
              seconds.
            </Typography>
          </li>
          <li className={classes.factsItem}>
            <MobileFriendlyIcon className={classes.factsItemIcon} fontSize="large" />
            <Typography variant="h5" component="p">
              <strong>Mobile friendly.</strong> Optimized for use on mobile phones and tablets.
            </Typography>
          </li>
        </ul>
        <Hint>
          <Typography variant="h5" component="p">
            Did you know your photos can hold supplemental metadata, which is typically hidden? Metadata may include
            geotags and reveal your privacy. Check out our{' '}
            <MuiLink href="https://jimpl.com">online EXIF data viewer</MuiLink> to see or remove metadata from your
            pictures.
          </Typography>
        </Hint>
      </div>
    </section>
  );
}

export default Facts;
