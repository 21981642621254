import App from 'components/App';
import store from 'store';
import { hydrate, render } from 'react-dom';

export const KNOWN_ACTIONS = ['resize', 'convert', 'crop', 'compress', 'rotate', 'flip'];

function removeServerJss() {
  Array.from(document.querySelectorAll('[data-jss-snap]')).forEach((elem) => elem.parentNode.removeChild(elem));
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App store={store} />, rootElement, removeServerJss);
} else {
  render(<App store={store} />, rootElement, removeServerJss);
}

window.snapSaveState = () => {
  Array.from(document.querySelectorAll('[data-jss]')).forEach((elem) => elem.setAttribute('data-jss-snap', ''));
};
