import SmShareButton from 'components/common/SmShareButton';
import { IoLogoFacebook, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io';

function SmShareButtons() {
  return (
    <>
      <SmShareButton
        name="Twitter"
        icon={IoLogoTwitter}
        color="#00aced"
        id="shareTwitter"
        href="https://twitter.com/intent/tweet?text=Secure%20and%20easy%20way%20to%20resize%2C%20convert%2C%20crop%2C%20compress%2C%20rotate%2C%20flip%20your%20images%2C%20photos%20or%20pictures%20online&url=https%3A%2F%2Fphotoutils.com&via=photoutils"
      />
      <SmShareButton
        name="Facebook"
        icon={IoLogoFacebook}
        color="#3b5998"
        id="shareFacebook"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fphotoutils.com"
      />
      <SmShareButton
        name="LinkedIn"
        icon={IoLogoLinkedin}
        color="#007fb1"
        id="shareLinkedin"
        href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fphotoutils.com&title=Simple%20Online%20Photo%20Editor%20%7C%20PhotoUtils"
      />
    </>
  );
}

export default SmShareButtons;
