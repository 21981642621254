import { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { actions, getIsFetching, getProgress, getTargetAction } from 'reducers/upload';
import { darkSecondaryColor, lightLinkColor } from 'components/themes';
import LoadableEditor from 'components/editor/LoadableEditor';
import { grey } from '@material-ui/core/colors';
import { Button, CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const borderRadius = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius,
  },
  title: {
    borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
    color: '#fff',
    width: '100%',
    padding: theme.spacing(2, 0),
    backgroundColor: grey[800],
  },
  content: {
    padding: theme.spacing(1, 3, 3, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  icon: {
    color: '#fff',
    fontSize: 64,
  },
  buttonContainer: {
    padding: theme.spacing(4, 2),
  },
  button: {
    height: 128,
    width: 128,
    backgroundColor: darkSecondaryColor,
    boxShadow: 'none',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#308CE8',
    },
  },
  buttonDisabled: {
    backgroundColor: `${darkSecondaryColor} !important`,
  },
  inProgressContainer: {
    height: 128,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressIcon: {
    marginRight: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(0, 3, 1, 3),
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    color: lightLinkColor,
  },
}));

function FeatureCard({
  onUpload,
  progress,
  targetAction,
  isFetching,
  icon: IconTag,
  action,
  title,
  children,
  ...rest
}) {
  const classes = useStyles();
  const input = useRef();
  const handleInputChange = useCallback(
    (e) => {
      const file = (e.target.files || e.dataTransfer.files)[0];
      if (file) {
        onUpload(file, action);
      }
    },
    [onUpload, action]
  );
  const handleKeyPress = useCallback(
    (e) => {
      if ((e.key === 'Enter' || e.key === ' ') && input.current && !isFetching) {
        input.current.click();
      }
    },
    [isFetching]
  );

  return (
    <Paper className={classes.root} {...rest}>
      <Typography className={classes.title} align="center" gutterBottom color="inherit" variant="h5" component="h2">
        {title}
      </Typography>
      <div className={classes.buttonContainer}>
        {isFetching && targetAction === action ? (
          <div className={classes.inProgressContainer}>
            <CircularProgress variant="indeterminate" className={classes.progressIcon} size={24} color="inherit" />
            <Typography color="inherit" variant="h6" component="span">
              {'Uploading...' + (progress > 0 ? ` ${progress}%` : '')}
            </Typography>
          </div>
        ) : (
          <Button
            classes={{ disabled: classes.buttonDisabled }}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyPress}
            id={`upload${action.charAt(0).toUpperCase() + action.slice(1)}`}
            aria-label={`Upload and ${action}`}
            disabled={isFetching}
            component="label"
            className={classes.button}
            onClick={() => {
              LoadableEditor.preload();
            }}
          >
            <IconTag className={classes.icon} />
            <input
              ref={input}
              name="file"
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              onChange={handleInputChange}
            />
          </Button>
        )}
      </div>
      <div className={classes.content}>
        <Typography align="center" variant="body1" color="textPrimary">
          {children}
        </Typography>
      </div>
      <div className={classes.footer}>
        <Button color="inherit" component={Link} to={`/how-to-${action}-an-image-online`}>
          How to {action} an image
        </Button>
      </div>
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: getIsFetching(state),
    targetAction: getTargetAction(state),
    progress: getProgress(state),
  };
}

export default connect(mapStateToProps, { onUpload: actions.request })(FeatureCard);
