import { call, put, race, select, take } from 'redux-saga/effects';
import axios from 'axios';
import { actions, getFormat as editorGetFormat, types } from 'reducers/editor';
import { getRoot as getResizeState } from 'reducers/resize';
import { getRoot as getCropState } from 'reducers/crop';
import { getRoot as getCompressState } from 'reducers/compress';
import { getDegrees } from 'reducers/rotate';
import { getMode } from 'reducers/flip';
import { types as uploadTypes } from 'reducers/upload';

function* getFormat(operation) {
  const format = yield select(editorGetFormat);
  return operation === 'compress' ? 'JPEG' : format;
}

function* prepareRequestData(operation) {
  const format = yield getFormat(operation);

  switch (operation) {
    case 'resize': {
      const resizeState = yield select(getResizeState);
      const { width, height } = resizeState;
      return { format, width, height };
    }
    case 'convert':
      return { format };
    case 'crop': {
      const cropState = yield select(getCropState);
      const { left, top, width, height } = cropState;
      return { format, left, top, width, height };
    }
    case 'compress': {
      const compressState = yield select(getCompressState);
      const { size, displayUnits } = compressState;
      const sizeInBytes = size * (displayUnits === 'kB' ? 1000 : 1000000);
      return { format, size: sizeInBytes };
    }
    case 'rotate': {
      const degrees = yield select(getDegrees);
      return { format, degrees };
    }
    case 'flip': {
      const mode = yield select(getMode);
      return { format, mode };
    }
    default:
      return null;
  }
}

function downloadAjaxResponse(response, format) {
  const type = response.headers['content-type'];
  const filename = response.headers['x-filename'] || `image.${format.toLowerCase()}`;

  let blob;
  try {
    blob = new File([response.data], filename, { type });
  } catch {
    blob = new Blob([response.data], { type });
  }

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    // use HTML5 a[download] attribute to specify filename
    // safari doesn't support this yet
    if (typeof a.download === 'undefined') {
      const reader = new FileReader();
      reader.onload = () => (window.location.href = reader.result);
      reader.readAsDataURL(blob);
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
}

function* executeOperation(action) {
  try {
    const { operation } = action;
    let format = yield getFormat(operation);

    const data = yield call(prepareRequestData, operation);
    const response = yield call(axios.post, `/api/${operation}`, data, { responseType: 'blob' });

    downloadAjaxResponse(response, format);

    yield put(actions.executeSuccess());
  } catch (e) {
    if (e.response && e.response.status && e.response.status === 424) {
      yield put(actions.sessionExpired(e));
    } else {
      yield put(actions.executeFailure(e));
    }
  }
}

export default function* editor() {
  while (true) {
    const action = yield take(types.EXECUTE_REQUEST);
    yield race({
      task: call(executeOperation, action),
      cancel: take(uploadTypes.CLEAR_REQUEST),
    });
  }
}
