import { connect } from 'react-redux';
import { actions, getSessionExpired } from 'reducers/editor';
import { LightThemeProvider } from 'components/themes';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function SessionExpiredDialog({ open, onDialogClose }) {
  return (
    <LightThemeProvider>
      <Dialog
        open={open}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Session expired</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session has expired after some period of inactivity. Upload the image again to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary" autoFocus>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </LightThemeProvider>
  );
}

function mapStateToProps(state) {
  return {
    open: getSessionExpired(state),
  };
}

const mapDispatchToProps = {
  onDialogClose: actions.resetError,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpiredDialog);
