import CameraIcon from '@material-ui/icons/PhotoCamera';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoIcon: {
    marginRight: theme.spacing(2),
  },
  logoText: {
    '&:hover': {
      textDecoration: 'none',
    },
    userSelect: 'none',
    display: 'inline',
  },
}));

function Logo({ iconSIze = 24, typographyVariant = 'h5' }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CameraIcon style={{ width: iconSIze, height: iconSIze }} className={classes.logoIcon} />
      <div>
        <Typography variant={typographyVariant} color="inherit" noWrap className={classes.logoText} component="span">
          PhotoUtils
        </Typography>
      </div>
    </div>
  );
}

export default Logo;
