import { StrictMode, useEffect } from 'react';
import Home from 'components/home/Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'components/router/ScrollToTop';
import NotFound from 'components/error/NotFound';
import { Provider, useSelector } from 'react-redux';
import SessionExpiredDialog from 'components/error/SessionExpiredDialog';
import { LightThemeProvider } from 'components/themes';
import LoadableEditor from 'components/editor/LoadableEditor';
import LoadableHelp from 'components/help/LoadableHelp';
import LoadableTerms from 'components/terms/LoadableTerms';
import LoadablePrivacy from 'components/privacy/LoadablePrivacy';
import { CssBaseline } from '@material-ui/core';
import GlobalCss from 'components/common/GlobalCss';
import { actions, getUploadInfo } from 'reducers/upload';
import {
  LoadableHowToCompress,
  LoadableHowToConvert,
  LoadableHowToCrop,
  LoadableHowToFlip,
  LoadableHowToResize,
  LoadableHowToRotate,
} from 'components/articles/LoadableHowToArticles';

function Main() {
  const uploadInfo = useSelector(getUploadInfo);
  return uploadInfo ? <LoadableEditor /> : <Home />;
}

function App({ store }) {
  useEffect(() => {
    store.dispatch(actions.infoInitialLoad());
  }, [store]);

  return (
    <StrictMode>
      <Provider store={store}>
        <CssBaseline />
        <GlobalCss />
        <LightThemeProvider>
          <Router>
            <Switch>
              <Route exact path={'/'} component={Main} />
              <Route exact path="/editor/:action" component={Main} />
              <Route exact path="/help" component={LoadableHelp} />
              <Route exact path="/terms" component={LoadableTerms} />
              <Route exact path="/privacy" component={LoadablePrivacy} />
              <Route exact path="/how-to-resize-an-image-online" component={LoadableHowToResize} />
              <Route exact path="/how-to-convert-an-image-online" component={LoadableHowToConvert} />
              <Route exact path="/how-to-crop-an-image-online" component={LoadableHowToCrop} />
              <Route exact path="/how-to-compress-an-image-online" component={LoadableHowToCompress} />
              <Route exact path="/how-to-rotate-an-image-online" component={LoadableHowToRotate} />
              <Route exact path="/how-to-flip-an-image-online" component={LoadableHowToFlip} />
              <Route component={NotFound} />
            </Switch>
            <ScrollToTop />
            <SessionExpiredDialog />
          </Router>
        </LightThemeProvider>
      </Provider>
    </StrictMode>
  );
}

export default App;
