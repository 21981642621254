import SplashScreen from 'components/common/SplashScreen';
import LoaderError from 'components/error/LoaderError';

function Loading({ error, pastDelay, retry }) {
  if (error) {
    return <LoaderError onRetryClick={retry} />;
  } else if (pastDelay) {
    return <SplashScreen />;
  } else {
    return null;
  }
}

export default Loading;
