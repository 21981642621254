import { types as uploadTypes } from 'reducers/upload';

export const types = {
  SET_SIZE: 'COMPRESS/SET_SIZE',
  SET_UNITS: 'COMPRESS/SET_UNITS',
};

const ONE_MB = 1000000;

const initialState = Object.freeze({
  size: 0,
  originalSizeInBytes: 0,
  displayUnits: 'kB',
});

export default function compress(state = initialState, action) {
  const { type, value } = action;
  switch (type) {
    case types.SET_SIZE:
      return { ...state, size: value };
    case types.SET_UNITS:
      return { ...state, displayUnits: value };
    case uploadTypes.INFO_LOADED: {
      const { sizeInBytes: originalSizeInBytes } = action.uploadInfo;
      const displayUnits = originalSizeInBytes - 1 >= ONE_MB ? 'MB' : 'kB';
      const divider = displayUnits === 'kB' ? 1000 : 1000000;
      const size = Math.floor(((originalSizeInBytes - 1) / divider) * 100) / 100;
      return { ...state, displayUnits, size, originalSizeInBytes };
    }
    default:
      return state;
  }
}

export const actions = {
  setSize: (value) => ({ type: types.SET_SIZE, value }),
  setUnits: (value) => ({ type: types.SET_UNITS, value }),
};

export const getRoot = (state) => state.compress;
