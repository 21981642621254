import { makeStyles } from '@material-ui/core';
import { lightGreen } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: lightGreen[50],
    borderLeft: `4px solid ${lightGreen[800]}`,
    padding: theme.spacing(3, 3, 3, 4),
  },
}));

function Hint({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export default Hint;
