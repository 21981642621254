import { Link } from 'react-router-dom';
import { DarkThemeProvider } from 'components/themes';
import { grey } from '@material-ui/core/colors';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: grey[900],
    color: theme.palette.text.secondary,
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    color: 'white',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography variant="subtitle1" color="inherit" component="p">
        &copy;
        {new Date().getFullYear()} PhotoUtils.com
      </Typography>
      <nav className={classes.nav}>
        <Typography className={classes.link} variant="subtitle1" component={Link} to="/help">
          Help and contacts
        </Typography>
        <Typography className={classes.link} variant="subtitle1" component={Link} to="/terms">
          Terms and conditions
        </Typography>
        <Typography className={classes.link} variant="subtitle1" component={Link} to="/privacy">
          Privacy policy
        </Typography>
      </nav>
      <Typography variant="subtitle1" color="inherit" component="p">
        Email us at <MuiLink href="mailto:support@photoutils.com">support@photoutils.com</MuiLink> for any questions
      </Typography>
    </footer>
  );
}

function DarkFooter() {
  return (
    <DarkThemeProvider>
      <Footer />
    </DarkThemeProvider>
  );
}

export default DarkFooter;
