export const types = {
  SET_MODE: 'FLIP/SET_MODE',
};

const initialState = Object.freeze({
  mode: 'FLOP',
});

export default function flip(state = initialState, action) {
  const { type, value } = action;
  switch (type) {
    case types.SET_MODE:
      return { ...state, mode: value };
    default:
      return state;
  }
}

export const actions = {
  setMode: (value) => ({
    type: types.SET_MODE,
    value,
  }),
};

export const getRoot = (state) => state.flip;
export const getMode = (state) => getRoot(state).mode;
