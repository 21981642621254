import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { Link } from 'react-router-dom';
import useHelmet from 'components/common/useHelmet';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(8, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
  },
}));

function NotFound() {
  const classes = useStyles();
  useHelmet('Page not found', '', 'https://photoutils.com');

  return (
    <>
      <Header />
      <main className={classes.content}>
        <Typography component="h1" variant="h3" align="center" gutterBottom>
          Aw, snap! :(
        </Typography>
        <Typography component="h2" variant="h4" align="center" gutterBottom>
          Error 404
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" gutterBottom>
          The page you are looking for could not be found
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary">
          You can return to the{' '}
          <MuiLink component={Link} to="/">
            Home page
          </MuiLink>{' '}
          instead
        </Typography>
      </main>
      <Footer />
    </>
  );
}

export default NotFound;
