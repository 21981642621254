export const types = {
  REQUEST: 'UPLOAD/REQUEST',
  INFO_INITIAL_LOAD: 'UPLOAD/INFO_INITIAL_LOAD',
  INFO_LOADED: 'UPLOAD/INFO_LOADED',
  FAILURE: 'UPLOAD/FAILURE',
  SET_PROGRESS: 'UPLOAD/SET_PROGRESS',
  RESET_TARGET_ACTION: 'UPLOAD/RESET_TARGET_ACTION',
  RESET: 'UPLOAD/RESET',
  CLEAR_REQUEST: 'UPLOAD/CLEAR_REQUEST',
};

const FILE_SIZE_LIMIT = 30000000; // 30 MB

const initialState = Object.freeze({
  isFetching: false,
  progress: 0,
  uploadInfo: undefined,
  targetAction: undefined,
  error: false,
  sizeExceededError: false,
  lastSelectedFileSize: 0,
  serial: 0,
});

export default function upload(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST: {
      const { size } = action.file;
      if (size && size > FILE_SIZE_LIMIT) {
        return { ...state, sizeExceededError: true, lastSelectedFileSize: size };
      }

      return { ...state, error: false, progress: 0, isFetching: true, targetAction: action.targetAction };
    }
    case types.INFO_LOADED:
      return action.uploadInfo
        ? {
            ...state,
            uploadInfo: Object.freeze(action.uploadInfo),
            progress: 0,
            error: false,
            sizeExceededError: false,
            isFetching: false,
            serial: state.serial + 1,
          }
        : state;
    case types.FAILURE:
      return { ...state, uploadInfo: undefined, targetAction: undefined, error: true, progress: 0, isFetching: false };
    case types.SET_PROGRESS:
      return { ...state, progress: action.value };
    case types.RESET_TARGET_ACTION:
      return { ...state, targetAction: undefined };
    case types.RESET:
    case types.CLEAR_REQUEST:
    case types.SESSION_EXPIRED:
      return { ...initialState, lastSelectedFileSize: state.lastSelectedFileSize };
    default:
      return state;
  }
}

export const actions = {
  request: (file, targetAction = undefined) => ({ type: types.REQUEST, file, targetAction }),
  infoInitialLoad: () => ({ type: types.INFO_INITIAL_LOAD }),
  infoLoaded: (uploadInfo) => ({ type: types.INFO_LOADED, uploadInfo }),
  failure: (error) => ({ type: types.FAILURE, error }),
  setProgress: (value) => ({ type: types.SET_PROGRESS, value }),
  reset: () => ({ type: types.RESET }),
  resetTargetAction: () => ({ type: types.RESET_TARGET_ACTION }),
  clearRequest: () => ({ type: types.CLEAR_REQUEST }),
};

export const getRoot = (state) => state.upload;
export const getIsFetching = (state) => getRoot(state).isFetching;
export const getTargetAction = (state) => getRoot(state).targetAction;
export const getProgress = (state) => getRoot(state).progress;
export const getError = (state) => getRoot(state).error;
export const getSizeExceededError = (state) => getRoot(state).sizeExceededError;
export const getLastSelectedFileSize = (state) => getRoot(state).lastSelectedFileSize;
export const getUploadInfo = (state) => getRoot(state).uploadInfo;
export const getSerial = (state) => getRoot(state).serial;
