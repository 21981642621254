import Loadable from 'components/common/Loadable';
import Loading from 'components/common/Loading';

export const LoadableHowToResize = Loadable({
  loader: () => import('components/articles/HowToResize'),
  loading: Loading,
});

export const LoadableHowToConvert = Loadable({
  loader: () => import('components/articles/HowToConvert'),
  loading: Loading,
});

export const LoadableHowToCrop = Loadable({
  loader: () => import('components/articles/HowToCrop'),
  loading: Loading,
});

export const LoadableHowToCompress = Loadable({
  loader: () => import('components/articles/HowToCompress'),
  loading: Loading,
});

export const LoadableHowToRotate = Loadable({
  loader: () => import('components/articles/HowToRotate'),
  loading: Loading,
});

export const LoadableHowToFlip = Loadable({
  loader: () => import('components/articles/HowToFlip'),
  loading: Loading,
});
