import { types as uploadTypes } from 'reducers/upload';

export const types = {
  SET_WIDTH: 'RESIZE/SET_WIDTH',
  SET_HEIGHT: 'RESIZE/SET_HEIGHT',
  SET_SIZE: 'RESIZE/SET_SIZE',
  SET_KEEP_PROPORTIONS: 'RESIZE/SET_KEEP_PROPORTIONS',
};

const initialState = Object.freeze({
  originalWidth: undefined,
  originalHeight: undefined,
  width: undefined,
  height: undefined,
  keepProportions: true,
});

export default function resize(state = initialState, action) {
  switch (action.type) {
    case types.SET_WIDTH: {
      const value = parseInt(action.value, 10);
      if (!value && value !== 0) {
        return { ...state, width: '' };
      }

      const width = Math.abs(value);
      let { height } = state;
      const { keepProportions, originalWidth, originalHeight } = state;
      if (keepProportions) {
        height = Math.round((originalHeight / originalWidth) * width);
      }
      return { ...state, width, height };
    }
    case types.SET_HEIGHT: {
      const value = parseInt(action.value, 10);
      if (!value && value !== 0) {
        return { ...state, height: '' };
      }

      const height = Math.abs(value);
      let { width } = state;
      const { keepProportions, originalWidth, originalHeight } = state;
      if (keepProportions) {
        width = Math.round((originalWidth / originalHeight) * height);
      }
      return { ...state, width, height };
    }
    case types.SET_KEEP_PROPORTIONS: {
      const value = action.value;
      let { height } = state;
      const { width, originalWidth, originalHeight } = state;
      if (!width || !height) {
        return { ...state, keepProportions: value };
      }

      if (value && originalHeight / originalWidth !== height / width) {
        height = Math.round((originalHeight / originalWidth) * width);
      }
      return { ...state, keepProportions: value, width, height };
    }
    case types.SET_SIZE: {
      const { width, height } = action;
      let { keepProportions } = state;
      const { originalWidth, originalHeight } = state;
      if (keepProportions && originalHeight / originalWidth !== height / width) {
        keepProportions = false;
      }
      return { ...state, keepProportions, width, height };
    }
    case uploadTypes.INFO_LOADED:
      return {
        ...state,
        originalWidth: action.uploadInfo.width,
        originalHeight: action.uploadInfo.height,
        width: action.uploadInfo.width,
        height: action.uploadInfo.height,
        keepProportions: true,
      };
    default:
      return state;
  }
}

export const actions = {
  setWidth: (value) => ({ type: types.SET_WIDTH, value }),
  setHeight: (value) => ({ type: types.SET_HEIGHT, value }),
  setSize: (width, height) => ({ type: types.SET_SIZE, width, height }),
  setKeepProportions: (value) => ({ type: types.SET_KEEP_PROPORTIONS, value }),
};

export const getRoot = (state) => state.resize;
