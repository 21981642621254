import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const primaryMain = '#d81b60';
export const lightLinkColor = blue[900];
export const darkLinkColor = '#fff';
export const darkSecondaryColor = '#64a9ee';

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: blue[700],
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiLink: {
      root: {
        color: lightLinkColor,
        '&:visited': {
          color: lightLinkColor,
        },
        '&:hover': {
          color: lightLinkColor,
        },
      },
    },
  },
});

const lightThemeOneColor = createMuiTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: blue[700],
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiLink: {
      root: {
        color: lightLinkColor,
        '&:visited': {
          color: lightLinkColor,
        },
        '&:hover': {
          color: lightLinkColor,
        },
      },
    },
  },
});

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: darkSecondaryColor,
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiLink: {
      root: {
        color: darkLinkColor,
        '&:visited': {
          color: darkLinkColor,
        },
        '&:hover': {
          color: darkLinkColor,
        },
      },
    },
  },
});

export function LightThemeProvider({ children, ...rest }) {
  return (
    <MuiThemeProvider theme={lightTheme} {...rest}>
      {children}
    </MuiThemeProvider>
  );
}

export function LightThemeOneColorProvider({ children, ...rest }) {
  return (
    <MuiThemeProvider theme={lightThemeOneColor} {...rest}>
      {children}
    </MuiThemeProvider>
  );
}
export function DarkThemeProvider({ children, ...rest }) {
  return (
    <MuiThemeProvider theme={darkTheme} {...rest}>
      {children}
    </MuiThemeProvider>
  );
}
