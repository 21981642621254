import { types as uploadTypes } from 'reducers/upload';

export const types = {
  SET_BOX: 'CROP/SET_BOX',
};

const initialState = Object.freeze({
  originalWidth: undefined,
  originalHeight: undefined,
  left: 0,
  top: 0,
  width: 1,
  height: 1,
});

export default function crop(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case types.SET_BOX: {
      const { x, y, width, height } = data;
      return {
        ...state,
        left: Math.round(x),
        top: Math.round(y),
        width: Math.round(width),
        height: Math.round(height),
      };
    }
    case uploadTypes.INFO_LOADED:
      return { ...state, originalWidth: action.uploadInfo.width, originalHeight: action.uploadInfo.height };
    default:
      return state;
  }
}

export const actions = {
  setBox: (data) => ({
    type: types.SET_BOX,
    data,
  }),
};

export const getRoot = (state) => state.crop;
