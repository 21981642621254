import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { actions, getSizeExceededError, types } from 'reducers/upload';
import axios from 'axios';
import store from 'store';

function* uploadImage(action) {
  try {
    const sizeExceededError = yield select(getSizeExceededError);
    if (sizeExceededError) {
      return;
    }

    const data = new FormData();
    data.append('file', action.file);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        store.dispatch(actions.setProgress(percentCompleted));
      },
    };

    const response = yield call(axios.post, '/api', data, config);
    yield put(actions.infoLoaded(response.data));
  } catch (e) {
    console.log(e);
    yield put(actions.failure(e));
  }
}

function* loadInitialUploadInfo() {
  if (navigator.userAgent === 'ReactSnap') {
    return;
  }
  try {
    const response = yield call(axios.get, '/api/uploadInfo');
    yield put(actions.infoLoaded(response.data));
  } catch (e) {
    // ignore
  }
}

function* clearUpload() {
  yield call(axios.post, '/api/clear');
}

function* cancelableUpload() {
  while (true) {
    const action = yield take(types.REQUEST);
    yield race({
      task: call(uploadImage, action),
      cancel: take(types.CLEAR_REQUEST),
    });
  }
}

export default function* upload() {
  yield fork(cancelableUpload);
  yield takeLatest(types.INFO_INITIAL_LOAD, loadInitialUploadInfo);
  yield takeLatest(types.CLEAR_REQUEST, clearUpload);
}
