import HelpIcon from '@material-ui/icons/Help';
import { Link } from 'react-router-dom';
import Logo from 'components/header/Logo';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    color: 'inherit',
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logoIcon: {
    marginRight: theme.spacing(2),
  },
  logoText: {
    '&:hover': {
      textDecoration: 'none',
    },
    userSelect: 'none',
    display: 'inline',
  },
}));

function HeaderContent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/" className={classes.logoContainer}>
        <Logo />
      </Link>
      <IconButton color="inherit" aria-label="Help" component={Link} to="/help">
        <HelpIcon />
      </IconButton>
    </div>
  );
}

export default HeaderContent;
