import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

function getHOCDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function Loadable({ loader, loading, delay = 1000 }) {
  function RetryWrapper(props) {
    const [retryCount, setRetryCount] = useState(0);
    const [pastDelay, setPastDelay] = useState(false);
    const retry = useCallback(() => {
      setRetryCount((prev) => prev + 1);
      setPastDelay(false);
    }, []);
    const LazyComponent = useMemo(
      () =>
        lazy(() =>
          loader().catch(() => {
            return { default: () => <LoadingView error retry={retry} /> };
          })
        ),
      [retryCount, retry] // eslint-disable-line react-hooks/exhaustive-deps
    );
    useEffect(() => {
      const id = setTimeout(() => setPastDelay(true), delay);
      return () => clearTimeout(id);
    }, [retryCount]);

    const LoadingView = loading;
    return (
      <Suspense fallback={<LoadingView pastDelay={pastDelay} />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  }

  RetryWrapper.displayName = `Loadable(${getHOCDisplayName(RetryWrapper)})`;
  function preload() {
    loader().catch(() => {
      return { default: () => null };
    });
  }
  RetryWrapper.preload = preload;

  return RetryWrapper;
}

export default Loadable;
