import ImageUploader from 'components/uploader/ImageUploader';
import SmShareButtons from 'components/common/SmShareButtons';
import LoadableEditor from 'components/editor/LoadableEditor';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  smButtons: {
    maxWidth: 625,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(2),
  },
  heroContent: {
    maxWidth: 625,
    margin: '0 auto',
    padding: theme.spacing(1, 0, 6),
  },
  heroDescription: {
    color: '#a5c8dc',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function HeroUnit() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <section className={classes.smButtons}>
        <SmShareButtons />
      </section>
      <section className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Free online photo editor
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center" paragraph className={classes.heroDescription}>
          Resize, convert, crop, compress, rotate, flip your images, photos or pictures online. It’s fast, secure, easy
          and free. To continue, click <strong>Upload Image</strong>.
        </Typography>
        <div className={classes.heroButtons}>
          <ImageUploader
            onClick={() => {
              LoadableEditor.preload();
            }}
            id="uploadMain"
          />
        </div>
      </section>
    </section>
  );
}

export default HeroUnit;
