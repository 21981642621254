import HeaderContent from 'components/header/HeaderContent';
import { grey } from '@material-ui/core/colors';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: grey[900],
  },
}));

function Header() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <HeaderContent />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
