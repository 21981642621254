import { useEffect } from 'react';

const defaultOgImage = {
  src: '/og-image.png',
  width: 400,
  height: 400,
  alt: 'PhotoUtils',
  type: 'image/png',
};

export default function useHelmet(title, description, canonical, ogProps = {}) {
  useEffect(() => {
    document.title = `${title} | PhotoUtils`;
    document.querySelectorAll('meta[name=description]')[0].content = description;
    document.querySelectorAll('link[rel=canonical]')[0].href = canonical;

    const { type = 'website', image = defaultOgImage, article } = ogProps;
    document.querySelectorAll('meta[property="og:url"]')[0].content = canonical;
    document.querySelectorAll('meta[property="og:title"]')[0].content = title;
    document.querySelectorAll('meta[property="og:description"]')[0].content = description;
    document.querySelectorAll('meta[property="og:type"]')[0].content = type;

    document.querySelectorAll('meta[property="twitter:title"]')[0].content = title;
    document.querySelectorAll('meta[property="twitter:description"]')[0].content = description;

    document.querySelectorAll('meta[property="og:image"]')[0].content = `https://photoutils.com${image.src}`;
    document.querySelectorAll('meta[property="og:image:width"]')[0].content = image.width;
    document.querySelectorAll('meta[property="og:image:height"]')[0].content = image.height;
    document.querySelectorAll('meta[property="og:image:alt"]')[0].content = image.alt;
    document.querySelectorAll('meta[property="og:image:type"]')[0].content = image.type;

    document.querySelectorAll('meta[property="twitter:image"]')[0].content = `https://photoutils.com${image.src}`;
    document.querySelectorAll('meta[property="twitter:image:alt"]')[0].content = image.alt;

    document.querySelectorAll('meta[property^="og:article"]').forEach((e) => e.remove());
    const head = document.getElementsByTagName('head')[0];
    if (article && head) {
      for (const [property, value] of Object.entries(article)) {
        const meta = document.createElement('meta');
        meta.setAttribute('property', `og:article:${property}`);
        meta.content = value;

        head.appendChild(meta);
      }
    }
  }, [title, description, canonical, ogProps]);
}
