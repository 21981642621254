import { makeStyles } from '@material-ui/core';
import Footer from 'components/footer/Footer';
import HeaderContent from 'components/header/HeaderContent';
import Features from 'components/home/Features';
import Facts from 'components/home/Facts';
import HeroUnit from 'components/home/HeroUnit';
import { DarkThemeProvider } from 'components/themes';
import useHelmet from 'components/common/useHelmet';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  topSection: {
    background: `radial-gradient(circle, ${grey[800]} 0%, ${grey[900]} 75%)`,
    color: '#fff',
  },
  header: {
    position: 'static',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  main: {
    overflowX: 'hidden',
    flexShrink: 0,
  },
}));

function Home() {
  const classes = useStyles();
  useHelmet(
    'Resize, convert, crop, compress, rotate, flip images online',
    'PhotoUtils is the free photo editor. Secure and easy way to resize, convert, crop, compress, rotate, flip your images, photos or pictures online',
    'https://photoutils.com'
  );

  return (
    <>
      <main className={classes.main}>
        <DarkThemeProvider>
          <section className={classes.topSection}>
            <header className={classes.header}>
              <HeaderContent />
            </header>
            <HeroUnit />
          </section>
        </DarkThemeProvider>
        <Features />
        <Facts />
      </main>
      <Footer />
    </>
  );
}

export default Home;
