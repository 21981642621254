import { withStyles } from '@material-ui/core';

export default withStyles({
  '@global': {
    strong: {
      fontWeight: '500',
    },
    a: {
      textDecoration: 'none',
      display: 'inline',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    'html, body, #root': {
      height: '100%',
      minWidth: 320,
      minHeight: 320,
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
})(() => null);
