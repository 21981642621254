import { types as uploadTypes } from 'reducers/upload';

export const types = {
  SET_FORMAT: 'EDITOR/SET_FORMAT',
  EXECUTE_REQUEST: 'EDITOR/EXECUTE_REQUEST',
  EXECUTE_SUCCESS: 'EDITOR/EXECUTE_SUCCESS',
  EXECUTE_FAILURE: 'EDITOR/EXECUTE_FAILURE',
  RESET_ERROR: 'EDITOR/RESET_ERROR',
  SESSION_EXPIRED: 'EDITOR/SESSION_EXPIRED',
};

const initialState = Object.freeze({
  isFetching: false,
  format: 'JPEG',
  error: false,
  sessionExpired: false,
});

export default function editor(state = initialState, action) {
  switch (action.type) {
    case types.EXECUTE_REQUEST:
      return { ...state, isFetching: true };
    case types.EXECUTE_SUCCESS:
      return { ...state, isFetching: false, error: false };
    case types.EXECUTE_FAILURE:
      return { ...state, isFetching: false, error: true };
    case types.SESSION_EXPIRED:
      return { ...state, isFetching: false, sessionExpired: true };
    case types.RESET_ERROR:
      return { ...state, error: false, sessionExpired: false };
    case types.SET_FORMAT:
      return { ...state, format: action.value };
    case uploadTypes.INFO_LOADED: {
      const { suggestedFormat } = action.uploadInfo;
      return { ...state, format: suggestedFormat, error: false, sessionExpired: false, isFetching: false };
    }
    default:
      return state;
  }
}

export const actions = {
  setFormat: (value) => ({
    type: types.SET_FORMAT,
    value,
  }),
  executeRequest: (operation) => ({
    type: types.EXECUTE_REQUEST,
    operation,
  }),
  executeSuccess: () => ({ type: types.EXECUTE_SUCCESS }),
  executeFailure: (error) => ({ type: types.EXECUTE_FAILURE, error }),
  resetError: () => ({ type: types.RESET_ERROR }),
  sessionExpired: () => ({ type: types.SESSION_EXPIRED }),
};

export const getRoot = (state) => state.editor;
export const getIsFetching = (state) => getRoot(state).isFetching;
export const getFormat = (state) => getRoot(state).format;
export const getError = (state) => getRoot(state).error;
export const getSessionExpired = (state) => getRoot(state).sessionExpired;
