import { DarkThemeProvider } from 'components/themes';
import Logo from 'components/header/Logo';
import { grey } from '@material-ui/core/colors';
import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    background: `radial-gradient(circle, ${grey[800]} 0%, ${grey[900]} 75%)`,
    color: '#fff',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    marginTop: theme.spacing(5),
  },
}));

function SplashScreen() {
  const classes = useStyles();

  return (
    <DarkThemeProvider>
      <div className={classes.root}>
        <div>
          <Logo iconSIze={48} typographyVariant="h3" />
          <LinearProgress color="secondary" className={classes.progress} />
        </div>
      </div>
    </DarkThemeProvider>
  );
}

export default SplashScreen;
