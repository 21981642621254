import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import upload from 'reducers/upload';
import rootSaga from 'sagas/root';
import resize from 'reducers/resize';
import editor from 'reducers/editor';
import rotate from 'reducers/rotate';
import flip from 'reducers/flip';
import crop from 'reducers/crop';
import compress from 'reducers/compress';

const reducer = combineReducers({
  upload,
  editor,
  resize,
  rotate,
  crop,
  flip,
  compress,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

function configureStore() {
  return createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
}

const store = configureStore();

sagaMiddleware.run(rootSaga);

export default store;
