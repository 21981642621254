import ResizeIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import ConvertIcon from '@material-ui/icons/SwapHoriz';
import CropIcon from '@material-ui/icons/Crop';
import CompressIcon from '@material-ui/icons/Archive';
import RotateIcon from '@material-ui/icons/ScreenRotation';
import FlipIcon from '@material-ui/icons/Flip';
import FeatureCard from 'components/home/FeatureCard';
import { darkSecondaryColor } from 'components/themes';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 2),
    backgroundColor: darkSecondaryColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardsContainer: {
    width: '100%',
    maxWidth: 1100,
  },
  cardsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gridGap: theme.spacing(5),
    justifyContent: 'center',
  },
}));

function Features() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.cardsContainer}>
        <section className={classes.cardsGrid}>
          <FeatureCard icon={ResizeIcon} action="resize" title="Resize image">
            Resize an image, enter any size in pixels or choose from popular social media image sizes
          </FeatureCard>
          <FeatureCard icon={ConvertIcon} action="convert" title="Convert image">
            Convert an image between different formats.
            <strong> JPG, PNG, WEBP, GIF, ICO, BMP, TIFF</strong> are supported.
          </FeatureCard>
          <FeatureCard icon={CropIcon} action="crop" title="Crop image">
            Select and crop any rectangle area on an image
          </FeatureCard>
          <FeatureCard icon={CompressIcon} action="compress" title="Compress image">
            File size of an image is often limited. Compress and optimize JPG image to the needed size in MB or kB.
          </FeatureCard>
          <FeatureCard icon={RotateIcon} action="rotate" title="Rotate image">
            Convert horizontal picture to vertical, vertical to horizontal or rotate an image 180&deg;
          </FeatureCard>
          <FeatureCard icon={FlipIcon} action="flip" title="Flip image">
            Mirror (flip) an image or photo horizontally or vertically
          </FeatureCard>
        </section>
      </div>
    </div>
  );
}

export default Features;
