export const types = {
  SET_DEGREES: 'ROTATE/SET_DEGREES',
};

const initialState = Object.freeze({
  degrees: 90,
});

export default function rotate(state = initialState, action) {
  const { type, value } = action;
  switch (type) {
    case types.SET_DEGREES:
      const degrees = parseInt(value);
      return { ...state, degrees };
    default:
      return state;
  }
}

export const actions = {
  setDegrees: (value) => ({
    type: types.SET_DEGREES,
    value,
  }),
};

export const getRoot = (state) => state.rotate;
export const getDegrees = (state) => getRoot(state).degrees;
