import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    width: 32,
    height: 32,
  },
}));

function SmShareButton({ color, icon, name, ...rest }) {
  const classes = useStyles();
  const IconTag = icon;

  return (
    <a
      aria-label={`Share this page on ${name}`}
      className={classes.root}
      tabIndex="-1"
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <IconTag className={classes.icon} />
    </a>
  );
}

export default SmShareButton;
