import { useCallback, useRef } from 'react';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { connect } from 'react-redux';
import { LightThemeProvider } from 'components/themes';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  actions,
  getError,
  getIsFetching,
  getLastSelectedFileSize,
  getProgress,
  getSizeExceededError,
  getTargetAction,
} from 'reducers/upload';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function ImageUploader({
  label = 'Upload image',
  disabled = false,
  targetAction,
  isFetching,
  progress,
  error,
  onUpload,
  onErrorDialogClose,
  sizeExceededError,
  lastSelectedFileSize,
  ...rest
}) {
  const classes = useStyles();
  const input = useRef();
  const handleInputChange = useCallback(
    (e) => {
      const file = (e.target.files || e.dataTransfer.files)[0];
      if (file) {
        onUpload(file);
      }
      const source = e.target || e.srcElement;
      source.value = '';
    },
    [onUpload]
  );
  const handleKeyPress = useCallback(
    (e) => {
      if ((e.key === 'Enter' || e.key === ' ') && input.current && !isFetching) {
        input.current.click();
      }
    },
    [isFetching]
  );

  const errorDialog = (
    <LightThemeProvider>
      <Dialog
        open={sizeExceededError}
        onClose={onErrorDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Image file is too big</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your image file size is <strong>{(lastSelectedFileSize / 1000000).toFixed(2)} MB</strong> while the limit is{' '}
            <strong>30 MB</strong>. Please select a smaller image and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onErrorDialogClose} color="primary" autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={error}
        onClose={onErrorDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upload failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Possible reasons are</DialogContentText>
          <ul>
            <Typography style={{ display: 'list-item' }} color="textSecondary" component="li">
              You are offline
            </Typography>
            <Typography style={{ display: 'list-item' }} color="textSecondary" component="li">
              Image format is unsupported
            </Typography>
            <Typography style={{ display: 'list-item' }} component="li" color="textSecondary">
              Image is too large, maximum allowed size is 30 MB
            </Typography>
            <Typography style={{ display: 'list-item' }} color="textSecondary" component="li">
              Uploaded file is not an image
            </Typography>
          </ul>
          <Typography color="textSecondary">Please check these reasons and try again</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onErrorDialogClose} color="primary" autoFocus>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </LightThemeProvider>
  );

  const isFetchingWithoutTargetAction = !targetAction && isFetching;
  const buttonLabel = isFetchingWithoutTargetAction ? 'Uploading...' + (progress > 0 ? ` ${progress}%` : '') : label;

  return (
    <>
      <Button
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyPress}
        disabled={disabled}
        component="label"
        size="large"
        variant="contained"
        color="primary"
        {...rest}
      >
        {isFetchingWithoutTargetAction ? (
          <CircularProgress variant="indeterminate" className={classes.icon} size={24} color="inherit" />
        ) : (
          <UploadIcon className={classes.icon} />
        )}
        {buttonLabel}
        {!isFetching && (
          <input
            ref={input}
            name="file"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleInputChange}
          />
        )}
      </Button>
      {errorDialog}
    </>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: getIsFetching(state),
    targetAction: getTargetAction(state),
    progress: getProgress(state),
    error: getError(state),
    sizeExceededError: getSizeExceededError(state),
    lastSelectedFileSize: getLastSelectedFileSize(state),
  };
}

export default connect(mapStateToProps, { onUpload: actions.request, onErrorDialogClose: actions.reset })(
  ImageUploader
);
